import { EventEmitter, Injectable } from "@angular/core";
import { CompanyRepository } from "../company.repository";

@Injectable()
export class CompanyService {
  private changeEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private companyRepository: CompanyRepository) {
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        localStorage.setItem("companyId", sessionStorage.getItem("companyId"));
      }
    });
  }

  public saveId(id: string) {
    localStorage.setItem("companyId", id);
    sessionStorage.setItem("companyId", id);
  }

  public setId(id: string) {
    this.saveId(id);
    if (id) {
      this.companyRepository.latest(id).subscribe();
    }
    this.changeEmitter.emit(id);
  }

  public getId() {
    let companyId = sessionStorage.getItem("companyId");
    if (!companyId) {
      companyId = localStorage.getItem("companyId");
    }
    this.saveId(companyId);
    return companyId;
  }

  public change() {
    return this.changeEmitter;
  }
}
