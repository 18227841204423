import { HttpResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CacheService } from "@info-click/cache";
import { JwtAuthGuard } from "@info-click/jwt-auth";
import { Pagination } from "@info-click/pagination";
import { Company } from "app/info-click/domains/company/company";
import { CompanyRepository } from "app/info-click/domains/company/company.repository";
import { CompanyService } from "app/info-click/domains/company/services/company.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-navbar-company-selector",
  templateUrl: "./navbar-company-selector.component.html",
  styleUrls: ["./navbar-company-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarCompanySelectorComponent implements OnInit, OnDestroy {
  public companies: Company[] = [];
  public otherCompanies: Company[] = [];
  public currentCompany: Company = new Company();
  public ready: boolean = false;

  private companySubscrition: Subscription;

  constructor(
    private companyRepository: CompanyRepository,
    private companyService: CompanyService,
    private auth: JwtAuthGuard,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private cache: CacheService
  ) {}

  ngOnInit(): void {
    this.currentCompany.name = "none";

    this.companySubscrition = this.companyRepository
      .search({ per_page: 100 })
      .subscribe({
        next: (response: HttpResponse<Pagination<Company>>) => {
          this.companies = this.filterCompanies(response.body.data).sort(
            (a, b) => (a.name > b.name ? 1 : -1)
          );

          // Seleziono l'ultima azienda selezionata dall'utente (ordinate in ordine cronologico)
          // oppure la prima azienda disponibile
          let companyId = window.sessionStorage.getItem("companyId") || null;
          if (!companyId && this.auth.user().latest_company_id) {
            companyId = this.auth.user().latest_company_id;
          }
          if (!companyId && this.companies[0]) {
            companyId = this.companies[0].id;
          }

          // Se non trovo l'azienda utile, seleziono la prima disponibile
          let currentCompany = this.companies.find(
            (company) => company.id === companyId
          );

          if (!currentCompany && this.auth.user().company_ids.length > 0) {
            currentCompany = this.companies.find(
              (company) => company.id === this.auth.user().company_ids[0]
            );
          }

          // Visualizzo solo se c'è più di un'azienda
          this.ready = this.companies.length > 0;
          this.setCompany(currentCompany);
        },
        error: (err) => console.error(err),
      });
  }

  ngOnDestroy(): void {
    this.companySubscrition?.unsubscribe();
  }

  private filterCompanies(companies: Company[]): Company[] {
    return companies.filter(
      (company) => this.auth.user().company_ids.indexOf(company.id) !== -1
    );
  }

  setCompany(company: Company): void {
    this.companyService.setId(company?.id || null);
    this.currentCompany = company || null;
    this.otherCompanies = this.companies.filter((c) => c.id !== company?.id);
    this.cache.flush();
    this.reloadCurrentRoute();
  }

  async reloadCurrentRoute() {
    const currentUrl = this.router.url;
    await this.router.navigateByUrl("/_blank", { skipLocationChange: true });
    await this.router.navigateByUrl(currentUrl);
    this.cdr.markForCheck();
  }
}
