import { Routes } from "@angular/router";

export const INFO_CLICK_ROUTES: Routes = [
  {
    path: "roles",
    loadChildren: () =>
      import("../pages/role/role.module").then((m) => m.RoleModule),
  },
  {
    path: "subscription-types",
    loadChildren: () =>
      import(
        "../pages/subscription-user-type/subscription-user-type.module"
      ).then((m) => m.SubscriptionUserTypeModule),
  },
  {
    path: "subscriptions",
    loadChildren: () =>
      import("../pages/subscription-user/subscription-user.module").then(
        (m) => m.SubscriptionUserModule
      ),
  },
  {
    path: "users",
    loadChildren: () =>
      import("../pages/user/user.module").then((m) => m.UserModule),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("../pages/setting/setting.module").then((m) => m.SettingModule),
  },
  {
    path: "errors",
    loadChildren: () =>
      import("../pages/error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("../pages/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "companies",
    loadChildren: () =>
      import("../pages/company/company.module").then((m) => m.CompanyModule),
  },
  {
    path: "company-groups",
    loadChildren: () =>
      import("../pages/company-group/company-group.module").then(
        (m) => m.CompanyGroupModule
      ),
  },
  {
    path: "_blank",
    loadChildren: () =>
      import("../pages/blank/blank.module").then((m) => m.BlankModule),
  },
];

export const INFO_CLICK_FULL_PAGE_ROUTES: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("../pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "recover",
    loadChildren: () =>
      import("../pages/recover/recover.module").then((m) => m.RecoverModule),
  },
];
