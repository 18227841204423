<li class="dropdown nav-item mr-2" display="dynamic" ngbDropdown *ngIf="ready">
  <ng-container *ngIf="otherCompanies.length < 1">
    <span class="nav-link d-flex align-items-center dropdown-toggle">
      <i class="ft-briefcase mr-1"></i>
      <span class="d-md-flex d-none">{{ currentCompany?.name }}</span>
    </span>
  </ng-container>

  <ng-container *ngIf="otherCompanies.length > 0">
    <a
      class="nav-link d-flex align-items-center dropdown-toggle"
      data-toggle="dropdown"
      ngbDropdownToggle
    >
      <i class="ft-briefcase mr-1"></i>
      <span class="d-md-flex d-none">{{ currentCompany?.name }}</span>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left">
      <a
        class="dropdown-item"
        *ngFor="let company of otherCompanies"
        (click)="setCompany(company)"
        ><span class="font-small-3">{{ company.name }}</span></a
      >
    </div>
  </ng-container>
</li>
